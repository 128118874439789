* {
	box-sizing: border-box;
}

body {
	height: 100vh;
	padding: 0;
	align-items: center;
	color: #eee;
	background: #151619;
}

#root {
	width: 100%;
	align-items: center;
	justify-content: center;
	display: flex;
}

div.inventory-item {
	background: rgba(0, 0, 0, 0.25);
	font-family: "Fontin SmallCaps";
	text-align: center;
	margin-bottom: 10px;
	padding-bottom: 4px;
}

div.inventory-item .header {
	height: 33px;
	font-size: 19px;
	line-height: 1em;
}

div.inventory-item.double .header {
	height: 54px;
	margin-bottom: 4px;
}

div.inventory-item-mod {
	padding: 1px 3px;
	font-size: 16px;
}

.item-container {
	position: relative;
}

.weapon-toggle {
	position: absolute;
	right: -25px;
	top: 35px;
	background: #999;
	width: 20px;
	height: 20px;
	cursor: pointer;
	border-radius: 3px;
}

.weapon-toggle:hover {
	background: #aaa;
}

.title {
	font-size: 150%;
	font-weight: bold;
	margin-top: 10px;
}

.normal {
	color: #c8c8c8;
}

.magic {
	color: #88f;
}

.rare {
	color: #ff7;
}

.unique {
	color: #af6025;
}

.enchanted-mod, .crafted-mod {
	color: #b4b4ff;
}

.explicit-mod, .implicit-mod, .utility-mod {
	color: #88f;
}

.corrupted {
	color: #d20000;
}

.separator {
	height: 8px;
	margin: 1px 0;
}

.normal .separator {
	background: url(images/separator-normal.png) center no-repeat;
}

.magic .separator {
	background: url(images/separator-magic.png) center no-repeat;
}

.rare .separator {
	background: url(images/separator-rare.png) center no-repeat;
}

.unique .separator {
	background: url(images/separator-unique.png) center no-repeat;
}

.normal .header {
	background: url(images/header-normal-left.png) top left no-repeat,
				url(images/header-normal-right.png) top right no-repeat,
				url(images/header-normal-middle.png) top center repeat-x;
}

.magic .header {
	background: url(images/header-magic-left.png) top left no-repeat,
				url(images/header-magic-right.png) top right no-repeat,
				url(images/header-magic-middle.png) top center repeat-x;
}

.rare .header {
	background: url(images/header-rare-left.png) top left no-repeat,
				url(images/header-rare-right.png) top right no-repeat,
				url(images/header-rare-middle.png) top center repeat-x;
}

.rare.double .header {
	background: url(images/header-double-rare-left.png) top left no-repeat,
				url(images/header-double-rare-right.png) top right no-repeat,
				url(images/header-double-rare-middle.png) top center repeat-x;
}

.unique .header {
	background: url(images/header-unique-left.png) top left no-repeat,
				url(images/header-unique-right.png) top right no-repeat,
				url(images/header-unique-middle.png) top center repeat-x;
}

.unique.double .header {
	background: url(images/header-double-unique-left.png) top left no-repeat,
				url(images/header-double-unique-right.png) top right no-repeat,
				url(images/header-double-unique-middle.png) top center repeat-x;
}

.header div {
	padding-top: 7px;
}

.double .header div {
	padding-top: 5px;
}